import React from 'react'
import classNames from 'classnames'

import * as classes from './blanket.module.scss'

export const BlanketContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(classes.content, className)} {...props} />
)
