import React from 'react'
import classNames from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'

import { TeamLogoQuery } from '../../types/graphql'

import * as classes from './team-logo.module.scss'

const getLogo = (
  logos: TeamLogoQuery['logos'],
  teamId: string,
  whiteFirst = false
) => {
  const logo = logos.nodes.find((logo) => logo.title === teamId)

  if (whiteFirst) {
    const whiteLogo = logos.nodes.find((logo) => logo.title === `${teamId}-wh`)

    return whiteLogo || logo
  }

  return logo
}

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  fluid?: boolean
  teamId: string
  teamLabel: string
  white?: boolean
}

export const TeamLogo: React.FC<Props> = ({
  className,
  fluid = false,
  teamId,
  teamLabel,
  white,
  ...props
}) => {
  const { logos } = useStaticQuery<TeamLogoQuery>(graphql`
    query TeamLogo {
      logos: allWordpressWpMedia(
        filter: { slug: { regex: "/^[0-9]{4}(-wh)?$|^[a-zA-Z]{2,3}(-wh)?$/" } }
        sort: { fields: [slug], order: DESC }
      ) {
        nodes {
          slug
          title
          localFile {
            publicURL
          }
        }
      }
    }
  `)

  const teamLogo = getLogo(logos, teamId, white)

  return (
    <img
      src={teamLogo?.localFile?.publicURL as string}
      alt={`Logo ${teamLabel}`}
      className={classNames(classes.logo, fluid && classes.fluid, className)}
      {...props}
    />
  )
}
