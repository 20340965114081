import React from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import moment from 'moment'

import { CalendarGame } from '../../reducers/calendar'

import { CalendarCollapse } from './CalendarCollapse'
import { TeamLogo } from '../TeamLogo'

import * as classes from './calendar.module.scss'
import { Button } from '../Button'

interface Props {
  currentDay: number
  gameDay: string
  games: CalendarGame[]
}

const getCollapseTitle = (gameDay: string, game?: CalendarGame) => {
  if (!game) {
    return `Giornata ${gameDay}`
  }

  return `Giornata ${gameDay}, ${moment(game.date).format('D MMM YYYY')} ${
    game.time ?? ''
  }`
}

export const CalendarDay = React.memo(
  ({ currentDay, gameDay, games }: Props) => {
    const REGame = games.find((game) => {
      return (
        game.homeTeamId === process.env.REACT_APP_RE_TEAM_ID ||
        game.awayTeamId === process.env.REACT_APP_RE_TEAM_ID
      )
    })

    return (
      <CalendarCollapse
        title={getCollapseTitle(gameDay, REGame)}
        className={classNames(
          classes.day,
          parseInt(gameDay) === currentDay + 1 && classes.active
        )}
        defaultCollapse={parseInt(gameDay) === currentDay + 1}
      >
        {games.map((game, index) => (
          <Row
            className={classNames(
              classes.game,
              (game.homeTeamId === process.env.REACT_APP_RE_TEAM_ID ||
                game.awayTeamId === process.env.REACT_APP_RE_TEAM_ID) &&
                classes.reTeam
            )}
            key={index}
          >
            <Col xs={12} md={2} className={classes.column}>
              <span className={classes.date}>
                {moment(game.date).format('D MMM YYYY')},
                {moment.utc(game.date).format('H:mm')}
              </span>
            </Col>

            <Col
              xs={4}
              md={3}
              className={classNames(classes.team, classes.homeTeam)}
            >
              <span className={classNames(classes.teamLabel)}>
                {game.homeTeam}
              </span>
              <div className={classes.logoContainer}>
                <TeamLogo
                  teamId={game.homeTeamId}
                  teamLabel={game.homeTeam}
                  className={classes.teamLogo}
                />
              </div>
            </Col>

            <Col xs={4} md={2} className={classes.score}>
              {moment(game.date).diff(new Date()) <= 0 ? (
                <p>
                  {game.homeScore} - {game.awayScore}
                </p>
              ) : (
                game.homeTeamId === process.env.REACT_APP_RE_TEAM_ID && (
                  <Button
                    as={'a'}
                    href={
                      'https://www.vivaticket.it/ita/location/palasport-palabigi/15145'
                    }
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                    className={classes.buyButton}
                  >
                    Acquista
                  </Button>
                )
              )}
            </Col>

            <Col xs={4} md={3} className={classes.team}>
              <div className={classes.logoContainer}>
                <TeamLogo
                  teamId={game.awayTeamId}
                  teamLabel={game.awayTeam}
                  className={classes.teamLogo}
                />
              </div>

              <span className={classNames(classes.teamLabel)}>
                {game.awayTeam}
              </span>
            </Col>

            <Col xs={12} md={2} className={classes.column}>
              {game.tv}
              &nbsp;
              {game.stadium}
            </Col>
          </Row>
        ))}
      </CalendarCollapse>
    )
  }
)
