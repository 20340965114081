import React from 'react'
import classNames from 'classnames'

import * as classes from './blanket.module.scss'

export const BlanketSubtitle = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h3 className={classNames(classes.subtitle, className)} {...props} />
)
