import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import plus from '../../images/plus.svg'
import minus from '../../images/minus.svg'
import classNames from 'classnames'

import { Title } from '../Title'

import * as classes from './calendar.module.scss'

interface CalendarCollapseProps {
  className?: string
  defaultCollapse?: boolean
  title: string
}

export const CalendarCollapse: React.FC<CalendarCollapseProps> = ({
  className,
  children,
  defaultCollapse = false,
  title,
}) => {
  const [collapseIn, setCollapseIn] = useState(defaultCollapse)

  return (
    <div className={classNames(classes.collapse, className)}>
      <Title
        as={'h6'}
        className={classes.title}
        onClick={() => setCollapseIn(!collapseIn)}
      >
        {title}
        <img src={collapseIn ? minus : plus} className={classes.icon} />
      </Title>

      <Collapse in={collapseIn} unmountOnExit mountOnEnter={false}>
        <div>{children}</div>
      </Collapse>
    </div>
  )
}
