import React from 'react'
import classNames from 'classnames'

import { Title, TitleProps } from '../Title'

import * as classes from './blanket.module.scss'

export const BlanketTitle = ({ className, ...props }: TitleProps) => (
  <Title className={classNames(classes.title, className)} {...props} />
)
