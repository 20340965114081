import React from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../reducers'
import { fetchCalendar } from '../../actions/calendar'
import { getCalendar } from '../../selectors/calendar'

import {
  Blanket,
  BlanketTitle,
  BlanketContent,
  BlanketBackground,
} from '../../components/Blanket'
import { CalendarDay } from '../../components/Calendar'
import { Container } from '../../components/Container'
import { CalendarCollapse } from '../../components/Calendar/CalendarCollapse'
import { SEO } from '../../components/Seo'
import { Spinner } from '../../components/Spinner'

interface Props {
  calendar: ReturnType<typeof getCalendar>
  fetchCalendar: typeof fetchCalendar
}

class Calendar extends React.Component<Props> {
  componentDidMount(): void {
    this.props.fetchCalendar()
  }

  getCalendarDays = () => {
    const { calendar } = this.props
    const days = Object.entries(calendar.data)
    const currentDay = calendar.currentDay

    if (currentDay > 0) {
      return {
        previousDays: days.slice(0, currentDay),
        nextDays: days.slice(currentDay),
      }
    }

    return {
      previousDays: [],
      nextDays: days,
    }
  }

  render() {
    const { calendar } = this.props
    const { previousDays, nextDays } = this.getCalendarDays()

    return (
      <Blanket>
        <SEO title={'Calendario e risultati'} />

        <BlanketBackground>
          <Container>
            <BlanketTitle>Calendario e risultati</BlanketTitle>

            <BlanketContent>
              <Spinner show={calendar.fetching} />

              {previousDays.length > 0 && (
                <CalendarCollapse title={'Giornate Precedenti'}>
                  {previousDays.map(([day, games]) => (
                    <CalendarDay
                      currentDay={calendar.currentDay}
                      gameDay={day}
                      games={games}
                      key={day}
                    />
                  ))}
                </CalendarCollapse>
              )}

              {nextDays.map(([day, games]) => (
                <CalendarDay
                  currentDay={calendar.currentDay}
                  gameDay={day}
                  games={games}
                  key={day}
                />
              ))}
            </BlanketContent>
          </Container>
        </BlanketBackground>
      </Blanket>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  calendar: getCalendar(state),
})

export default connect(mapStateToProps, { fetchCalendar })(Calendar)
