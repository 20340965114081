import { AppState } from '../reducers'

export const getCalendar = (state: AppState) => {
  const { calendar } = state

  return {
    ...calendar,
    currentDay: Object.entries(calendar.data).findIndex((day) => {
      const { date } = day[1][0]

      return new Date() < new Date(date)
    }),
  }
}
