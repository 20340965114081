import React from 'react'
import classNames from 'classnames'

import { Layout } from '../Layout'

import * as classes from './blanket.module.scss'

export { BlanketBackground } from './BlanketBackground'
export { BlanketContent } from './BlanketContent'
export { BlanketTitle } from './BlanketTitle'
export { BlanketSubtitle } from './BlanketSubtitle'

interface BlanketProps extends React.HTMLAttributes<HTMLDivElement> {
  dark?: boolean
  noPadding?: boolean
}

export const Blanket = ({
  children,
  dark = true,
  noPadding = false,
}: BlanketProps) => (
  <Layout
    className={classNames(
      classes.blanket,
      dark && classes.dark,
      noPadding && classes.noPadding
    )}
  >
    {children}
  </Layout>
)
