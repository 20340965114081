import React from 'react'
import {
  Spinner as BootstrapSpinner,
  SpinnerProps as BootstrapSpinnerProps,
} from 'react-bootstrap'
import classNames from 'classnames'

import * as classes from './spinner.module.scss'

interface SpinnerProps extends Omit<BootstrapSpinnerProps, 'animation'> {
  animation?: BootstrapSpinnerProps['animation']
  show?: boolean
}

export const Spinner: React.FC<SpinnerProps> = ({
  animation = 'grow',
  show = false,
  ...props
}) => {
  if (!show) {
    return null
  }

  return (
    <div className={classes.container}>
      <BootstrapSpinner
        animation={animation}
        className={classes.spinner}
        {...props}
      />
    </div>
  )
}
