// extracted by mini-css-extract-plugin
export var active = "calendar-module--active--qnA41";
export var buyButton = "calendar-module--buyButton--eG40X";
export var collapse = "calendar-module--collapse--jAT1j";
export var column = "calendar-module--column--clAHl";
export var date = "calendar-module--date--OvLog";
export var day = "calendar-module--day--0Msub";
export var homeTeam = "calendar-module--homeTeam---8v1s";
export var icon = "calendar-module--icon--tMPhs";
export var logoContainer = "calendar-module--logoContainer--NScZZ";
export var reTeam = "calendar-module--reTeam--7w104";
export var score = "calendar-module--score--lOZev calendar-module--column--clAHl";
export var team = "calendar-module--team--A1zMS calendar-module--column--clAHl";
export var teamLabel = "calendar-module--teamLabel--Nc78U";
export var teamLogo = "calendar-module--teamLogo--Q3Blr";
export var title = "calendar-module--title--E+QAw";