import React from 'react'
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
  ButtonToolbar as BootstrapButtonToolbar,
  ButtonToolbarProps as BootstrapButtonToolbarProps,
} from 'react-bootstrap'
import classNames from 'classnames'

import * as classes from './button.module.scss'

export type ButtonProps = BootstrapButtonProps & {
  className?: string
  [key: string]: any
}

export const Button = ({
  className,
  children,
  variant = 'primary',
  ...props
}: ButtonProps) => (
  <BootstrapButton
    className={classNames(classes.button, className)}
    variant={variant}
    {...props}
  >
    <span>{children}</span>
  </BootstrapButton>
)

type ButtonToolbarProps = React.HTMLAttributes<HTMLDivElement> &
  BootstrapButtonToolbarProps

export const ButtonToolbar: React.FC<ButtonToolbarProps> = ({
  className,
  children,
  ...props
}) => (
  <BootstrapButtonToolbar
    className={classNames(classes.buttonToolbar, className)}
    {...props}
  >
    {children}
  </BootstrapButtonToolbar>
)
