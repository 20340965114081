import React from 'react'
import classNames from 'classnames'

import { Container } from '../Container'

import * as classes from './blanket.module.scss'

export const BlanketBackground = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <Container className={classes.noPaddingMobileContainer}>
    <div className={classNames(classes.background, className)} {...props} />
  </Container>
)
